.chatbot-card {
    position: fixed;
    bottom: 0;
    right: 0;
    font-size: 16px;
    border: none;
    z-index: 100000;
    height: 800px;
    width: 400px;
    border-radius: .6rem;
}

@media (max-height: 800px) {
    .chatbot-card {
        height: 100%;
    }
}

@media (max-width: 576px) {
    .chatbot-card {
        height: 100%;
        width: 100%;
    }
}

.chatbot-header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    color: white;
    background: #ff580c;
    border-radius: .6rem .6rem 0 0;
    height: 80px;
}

.chatbot-header-identity-container {
    display: flex;
    align-content: center;
}

.messages-container {
    display: grid;
    grid-auto-rows: min-content;
    background-color: white;
    height: calc(100% - 80px - 95px);
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.messages-container::-webkit-scrollbar {
    display: none;
}

.chatbot-footer-container {
    background-color: #ffe7dc;
    display: block;
    bottom: 0;
    width: 100%;
    height: 95px;
    font-size: 16px;
}

.chatbot-footer {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 1.6rem;
}

.chatbot-question,
.chatbot-response {
    padding: .7rem 1.2rem;
    display: flex;
}

.avatar-container .avatar {
    border-radius: 50%;
    height: 56px;
    width: 56px;
}

.avatar-container {
    min-width: 70px;
    padding: 0 .5rem;
}

.avatar-container > .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F6DDCC;
    color: #ff580c;
    border-radius: 50%;
    height: 3.5rem;
    width: 3.5rem;
    text-align: center;
    padding: 1rem;
}

.identity-container {
    margin-top: 10px;
    line-height: 18px;
}

.chatbot-question {
    justify-self: end;
}

.chatbot-question > .chatbot-message-content {
    padding: 1rem;
    border: 1px solid #E7E7E9;
    border-radius: 16px 0 16px 16px;
    background-color: #fbfbfb;
}

.chatbot-response > .chatbot-message-content {
    padding: 1rem;
    background-color: rgb(255, 88, 12, .25);
    border-radius: 0 16px 16px 16px;
}

.chatbot-send-message-button {
    color: #ff580c;
    padding: 1.5rem;
}

.chatbot-footer > textarea {
    border-radius: .6rem;
    border: 1px solid #E7E7E9;
    height: 62px;
    font-size: 16px;
}

.form-control:focus{
    box-shadow: none;
}

.form-control::placeholder{
    color: #C4C4C4;
}

.chat-button {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 3rem 2rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 10000;
}

.speech-bubble {
    display: flex;
    position: relative;
    color: white;
    background: #ef790b;
    border-radius: .4em;
    line-height: 20px;
    padding: 10px;
    margin-bottom: 10px;
}

.speech-bubble .close-bubble-icon {
    margin: 0 .6rem 0 1.4rem;
}

.speech-bubble:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 5px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #ef790b;
    border-bottom: 0;
    border-left: 0;
    margin-bottom: -10px;
}